import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import moment from 'moment'

interface BonusListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  filter: any;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  documents: any[];
  brands: any[];
  products: any[];
}

const $state: BonusListState = {
  loading: false,
  loaded: false,
  fatalError: false,

  filter: {
    status: 'confirmation',
    brands: [],
    products: [],
  },
  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  documents: [],
  brands: [],
  products: [],
}

export const $actions: ActionTree<BonusListState, RootState> = {
  loadData({ state, commit, getters, dispatch }) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      let params: any = {}

      if (state.filter) {
        params = {
          start_date: state.filter.start_date,
          end_date: state.filter.end_date,
          brands: state.filter.brands && state.filter.brands.length ? JSON.stringify(state.filter.brands.map((i: any) => i.id)) : null,
          products: state.filter.products && state.filter.products.length ? JSON.stringify(state.filter.products.map((i: any) => i.id)) : null,
          status: state.filter.status,
        }
      }

      // if (query.sortField) {
      //     params.order_by = query.sortField;
      //     params.order_direction = query.sortDirection || 'asc';
      // }

      // params.page = query.page || 1;

      // if (query.name) {
      //     params.search = query.name;
      // }

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/cashbacks/transactions', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')
          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  setFilter({ state, dispatch }, filter) {
    state.filter = filter
    dispatch('loadData')
  },
  acceptTransaction({ state, commit }, transaction: any) {
    console.log(transaction)
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      vm.$http
        .patch('/admins/rest/v1/cashbacks/transactions/' + transaction.id, { transaction: { status: 'confirmed' } })
        .then((response: AxiosResponse) => {
          // commit('SET_ITEM_STATUS', {transaction_id: transaction.id, status: response.data.status});
          commit('DEL_ITEM', transaction)
          commit('LOADED_SUCCESS')
          resolve(null)
        })
        .catch((error: any) => {
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  rejectTransaction({ state, commit }, transaction: any) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      vm.$http
        .patch('/admins/rest/v1/cashbacks/transactions/' + transaction.id, {
          transaction: {
            status: 'rejected',
            reject_details: transaction.reject_details,
          },
        })
        .then((response: AxiosResponse) => {
          commit('DEL_ITEM', transaction)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },

  loadBrands({ commit, state }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/brands`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_BRANDS', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  loadProducts({ commit, state }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/products`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_PRODUCTS', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

}

const compare: any = ((a: any, b: any) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
})

export const $mutations: MutationTree<BonusListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_ITEM_STATUS(state, { transaction_id, status }) {
    state.documents.map((item: any) => {
      if (item.id === transaction_id) {
        item.status = status
      }
    })
  },
  SET_ITEMS_LIST(state, data) {
    state.documents = data.transactions.map((item: any) => {
      item.editable = moment(item.start_date).add(12, 'hours') > moment()
      return item
    })

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },
  DEL_ITEM(state, transaction) {
    const _idx = state.documents.indexOf(transaction)
    console.log(_idx)
    state.documents.splice(_idx, 1)
  },
  SET_BRANDS: (state, { brands }) => {
    state.brands = brands.sort(compare)
  },
  SET_PRODUCTS: (state, { products }) => {
    state.products = products.map((item: any) => {
      return {
        id: item.id,
        name: item.nomenclature.name,
      }
    }).sort(compare)
  },
}

export const $getters: GetterTree<BonusListState, RootState> = {}

export const STORE_KEY = '$_cashback_transactions_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
