import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { merge } from 'lodash'

interface CityFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  settings: any;
  categories: any[];
  form: any;
}

const $state: CityFormState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  settings: null,
  categories: [],
  form: null,
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  expire: 90,
}

const AVAILABLE_FIELDS = ['name', 'external_id_fias', 'external_id_kladr', 'point']

export const $actions: ActionTree<CityFormState, RootState> = {

  initState({ commit, state }, { id, predefined }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm
      Promise.all([
        vm.$http.get(`/admins/rest/v1/settings`),
        vm.$http.get(`/admins/rest/v1/merchant_categories`),
      ])
        .then(([response]) => {
          commit('SET_FORM_DATA', {
            settings: response.data.settings,
          })
          commit('LOADED_SUCCESS')

          resolve(state.settings)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  prepareNew({ commit, state }, predefined) {
    return new Promise((resolve, reject) => {
      commit('GENERATE_FORM_DATA', predefined)
      commit('LOADED_SUCCESS')
      resolve(null)
    })
  },

  changeFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
  },

  saveObject({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      commit('START_LOADING')

      let request = null

      request = vm.$http.patch(`/admins/rest/v1/settings`, {
        settings: state.settings,
      })
      request
        .then((response: AxiosResponse) => {
          commit('LOADED_SUCCESS')

          resolve(response.data)
        })
        .catch((errorResponse: AxiosError) => {
          // eslint-disable-next-line
          console.error('Fatal error', errorResponse)

          if (errorResponse.response?.status === 500) {
            commit('LOADED_ERROR')
            reject()
          }
          else {
            const serverError = errorResponse.response?.data?.error

            commit('LOADED_SUCCESS')
            commit('SET_SERVER_ERROR', serverError)
            reject(serverError)
          }
        })
    })
  },
}

export const $mutations: MutationTree<CityFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },

  SET_FORM_DATA(state, { settings, categories }) {
    state.settings = settings

    const formData = cloneDeep(state.settings)

    state.form = {
      ...formData,
    }
  },

  GENERATE_FORM_DATA(state, predefined) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
      ...predefined,
    }
  },

  CHANGE_VALUE(state: CityFormState, { field, newValue }) {
    state.form[field] = newValue
  },

  ON_FORM_SAVE(state, { settings }) {
    state.settings = settings

    const formData = cloneDeep(settings)

    state.form = {
      ...formData,
    }
  },

}

const $getters = {}

export const STORE_KEY = '$_settings_form'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
