































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'

import CityList from '../CityList/CityList.vue'
import MerchantCategoryList from '../MerchantCategoryList/MerchantCategoryList.vue'
import ProductCategoriyList from '../ProductCategoriyList/ProductCategoriyList.vue'
import ProductPatternList from '../ProductPatternList/ProductPatternList.vue'
import Nomenclature from '../Nomenclature/Nomenclature.vue'
import PrivacyPolicyForm from '../TextEditors/PrivacyPolicyForm/PrivacyPolicyForm.vue'
import OnboardingList from '../OnboardingList/OnboardingList.vue'
import BonusList from '../BonusList/BonusList.vue'
import PromotionList from '../PromotionList/PromotionList.vue'
import Cashback from '../Cashback/СashbackList.vue'
import CashbackTransactions from '../CashbackTransactions/CashbackTransactions.vue'
import SettingsForm from '../SettingsForm/SettingsForm.vue'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form',
    ]),
  },
  methods: {},
  components: {
    TitleBar,
    CityList,
    MerchantCategoryList,
    ProductCategoriyList,
    ProductPatternList,
    Nomenclature,
    PrivacyPolicyForm,
    OnboardingList,
    BonusList,
    PromotionList,
    Cashback,
    CashbackTransactions,
    SettingsForm,
  },
})
export default class SettingsTabs extends Vue {
  public active_tab: string = 'cities'

  public tabChanged() {
    // this.$forceUpdate();
  }

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/initState`, {})

    if ('active_tab' in this.$route.params) {
      this.active_tab = this.$route.params.active_tab
    }
    else {
      this.active_tab = 'cities'
    }
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
