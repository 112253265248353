import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { merge } from 'lodash'

interface SettingsTabsState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  form: any;
}

const $state: SettingsTabsState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  form: null,
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  expire: 90,
}

const AVAILABLE_FIELDS = []

export const $actions: ActionTree<SettingsTabsState, RootState> = {
  initState({ commit, state }, { id, predefined }) {
    commit('LOADED_SUCCESS')
  },
}

export const $mutations: MutationTree<SettingsTabsState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },
}

const $getters = {}

export const STORE_KEY = '$_settings_tabs'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
