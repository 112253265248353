import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import moment from 'moment'

interface BonusListState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  filter: any;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  documents: any[];
  merchants: any[];
}

const $state: BonusListState = {
  loading: false,
  loaded: false,
  fatalError: false,

  filter: {},
  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  documents: [],
  merchants: [],
}

export const $actions: ActionTree<BonusListState, RootState> = {
  preloadData({ commit, getters, dispatch }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get('/admins/rest/v1/merchants', { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_MERCHANTS_LIST', response.data)
          commit('LOADED_SUCCESS')
          resolve(response.data)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })

  },
  loadData({ commit, getters, dispatch }, filter) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      let params: any = {}

      if (filter) {
        params = {
          merchant_id: filter.merchant_id,
          status: filter.status,
          category: filter.category,
          name: filter.name,
        }
      }

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/cashbacks', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')
          resolve(response.data)
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },

}

const compare: any = ((a: any, b: any) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
})

export const $mutations: MutationTree<BonusListState> = {
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_MERCHANTS_LIST(state, { merchants }) {
    state.merchants = merchants
  },
  SET_ITEMS_LIST(state, data) {
    state.documents = data.cashbacks.map((item: any) => {
      item.editable = moment(item.start_date).add(12, 'hours') > moment()
      return item
    })

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },
}

export const $getters: GetterTree<BonusListState, RootState> = {}

export const STORE_KEY = '$_cashback_list'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
