










































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty', 'settings',
    ]),
  },
  methods: {
    getFieldError,
  },
  components: {
    TitleBar,
  },
})
export default class SettingsForm extends Vue {
  @Prop({ type: String }) public filter!: string
  @Prop({ type: String }) public filter_reverse!: string

  get isNew() {
    return this.$route.params.id !== 'new'
  }

  get titleStack() {
    return [
      'Настройки', 'Cистемные',
    ]
  }

  public filter_settings(setting: any) {
    const R = new RegExp(this.filter)
    return setting.filter(
      (s: any) => {
        let b = R.test(s.label)
        if (this.filter_reverse === 'true') {
          b = !b
        }

        return b
      },
    )
  }

  public changeValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  public submitObject() {
    this.$store.dispatch(`${STORE_KEY}/saveObject`)
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/initState`, {})
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
