








































































































































































































































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import moment from 'moment'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'fatalError', 'totalCount',
      'filter', 'merchants', 'documents', 'lastMeta',
      'brands', 'products',
    ]),
  },
  methods: {
    ...mapActions(STORE_KEY, [
      'exportData',
    ]),
  },
  components: {
    TitleBar,
  },
})
export default class CashbackTransactions extends Vue {

  public showModal: any = false
  public defaultOpenedDetails: any[] = []
  public filter!: any
  public nameState: any = null
  public rejectable: any = null
  public rejectable_many: boolean = false
  public reject_details: string = ''
  public checkedRows: any[] = []

  get titleStack() {
    return [
      'Кэшбэк',
    ]
  }

  public onClearSearch() {
    this.$router.replace({
      query: {},
    })
    this.showModal = !this.showModal
  }

  public onSearch() {
    const filter: any = {}
    for (const key of Object.keys(this.filter)) {
      if (this.filter[key]) {
        if (key === 'cities') {
          filter.cities = this.filter[key].map((c: any) => c.id)
        }
        else {
          filter[key] = this.filter[key]
        }
      }
    }
    this.$store.dispatch(`${STORE_KEY}/setFilter`, filter)
    this.showModal = !this.showModal
  }

  public acceptTransactionAll() {
    this.checkedRows.map((item: any) => {
      this.acceptTransaction(item)
    })
  }

  public acceptTransaction(transaction: any) {
    this.$store.dispatch(`${STORE_KEY}/acceptTransaction`, transaction)
  }

  public rejectModalOpenAll(transaction: any) {
    this.$bvModal.show('reject-modal')
    this.rejectable = null
    this.rejectable_many = true
  }

  public rejectModalOpen(transaction: any) {
    this.$bvModal.show('reject-modal')
    this.rejectable = transaction
    this.rejectable_many = false
  }

  public checkFormValidity() {
    // @ts-ignore
    const valid = this.$refs.form.checkValidity()
    this.nameState = valid
    return valid
  }

  public rejectTransaction(bvModalEvt: any) {
    bvModalEvt.preventDefault()

    if (!this.checkFormValidity()) {
      return
    }
    if (this.rejectable_many) {
      this.checkedRows.map((item: any) => {
        item.reject_details = this.reject_details
        this.$store.dispatch(`${STORE_KEY}/rejectTransaction`, item)
      })
    }
    else {
      this.rejectable.reject_details = this.reject_details
      this.$store.dispatch(`${STORE_KEY}/rejectTransaction`, this.rejectable)
    }
    this.rejectable = null
    this.rejectable_many = false
    this.$nextTick(() => {
      this.$bvModal.hide('reject-modal')
    })
  }

  // ---------------------------------------------------------------------------

  public onPageChange(page: string) {
    this.$router.replace({
      query: { ...this.$route.query, page },
    })
  }

  @Watch('$route.query')
  public handleParamsChange() {
    this.$store.dispatch(`${STORE_KEY}/loadProducts`)
    this.$store.dispatch(`${STORE_KEY}/loadBrands`)
    this.$store.dispatch(`${STORE_KEY}/loadData`)
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
